<template>
<div>
    <v-btn v-if="btnType==='Submit'"
  class="btn btnSubmit"
    @click.prevent="onClick"
    :disabled="disabled"
    :loading="isLoading"
  >{{label}}</v-btn>

   <v-btn v-if="btnType==='Cancel'"
  class="btn btnCancel"
    @click.prevent="onClick"
    :disabled="disabled"
    :loading="isLoading"
  >{{label}}</v-btn>

</div>
</template>
<script>
export default {
  props: {
    btnType: {
      type: String
    },
    label: {
      type: String
    },
    // onClick: {
    //   type: Function,
    //   required: true
    // },
    disabled: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>
<style scoped>
.btnSubmit{
  background: #0066b2 !important;
}
.btnCancel{
background-color: #dc3545 !important;
}
.btn{
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 11px !important;
    padding: 8px 20px;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 0;
}
</style>
